import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Paper, Table, Toolbar } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Accordion from '@material-ui/core/Accordion';
import _get from 'lodash/get';
import {
  findRolesInUser,
  isCompletedPatient,
  isLabPartner,
  isSuperAdminOrAdmin,
  UTCDateTimeToLocalTime
} from '../../../helpers';
import { fetchDownloadUrlPrintingFileAction } from '../../../reducers/printingFiles';
import Can from '../../../components/Can';
import { deletePatientFileAction } from '../../../reducers/patients';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import PatientModificationWarning from '../../../components/PatientModificationWarning';
import { openPatientModificationWarning } from '../../../reducers/modals';
import OpenWithExternalApp from '../../../components/OpenWithExternalApp';
import { openDIBS } from '../../../helpers/dibs';
import ReluRequestStatusChip from './ReluRequestStatusChip';
import FlagJobAction from './FlagJobAction';
import tags from '../../../constants/tags';

const useStyles = makeStyles(theme => ({
  tableHeader: {
    backgroundColor: theme.palette.primary.light
  },
  noFoundChip: {
    marginLeft: theme.spacing(2)
  },
  addButton: {
    marginLeft: theme.spacing(2)
  },
  AccordionSummary: {
    '& > .MuiExpansionPanelSummary-content': {
      justifyContent: 'space-between'
    }
  },
  labels: {
    marginLeft: theme.spacing(2),
    display: 'inline',
    '& > .MuiChip-colorSecondary': {
      marginLeft: theme.spacing(0.5)
    }
  }
}));

const PatientFilesTable = ({
  patientFiles,
  patient,
  patientModificationWarningId,
  actions,
  title,
  addTitle,
  openUploadDialog,
  fileTypeId,
  isPatientDeleted,
  preventDuplicate,
  maxFiles,
  organizationId,
  disableUpload,
  renderAdditionalButtons,
  onFileDeleted,
  onFileUpdated,
  validateFile
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentUser = useSelector(state => state.auth.user);
  const [isDeletingFile, setIsDeletingFile] = useState(false);

  const [currentFile, setCurrentFile] = useState(null);
  const [selectedApp, setSelectedApp] = useState(null);

  const handleOpenFile = (app, file) => {
    setCurrentFile(file);
    setSelectedApp(app);

    if (isCompletedPatient(patient)) {
      dispatch(openPatientModificationWarning(patientModificationWarningId));
      return;
    }

    dispatch(openDIBS(app, `open_file/${file.id}`));
  };

  const onPatientModificationWarningClose = answer => {
    if (answer) {
      dispatch(openDIBS(selectedApp, `open_file/${currentFile.id}`));
    }
  };

  const renderAddButton = () => (
    <Can
      permissions={[
        'patient-files.manage',
        'patient-files.create',
        'organization.patient-files.create'
      ]}
      organizationId={organizationId}
      yes={() => (
        <Button
          className={classes.addButton}
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          color="primary"
          onClick={event => {
            event.stopPropagation();
            openUploadDialog({
              title: addTitle || title,
              fileTypeId,
              isImage: false,
              maxFiles,
              preventDuplicate,
              existingFiles: patientFiles,
              nameValidator: null,
              validateFile
            });
          }}
        >
          Add
        </Button>
      )}
    />
  );

  const handleDownloadFile = id => {
    dispatch(fetchDownloadUrlPrintingFileAction(id));
  };

  const handleDeleteFile = (patientId, patientFileTypeId, id) => {
    setIsDeletingFile(true);
    dispatch(deletePatientFileAction(patientId, patientFileTypeId, id)).finally(() => {
      setIsDeletingFile(false);
      onFileDeleted(id);
    });
  };

  const renderActions = patientFile => {
    return actions
      .filter(action => {
        if (action.roles) {
          return findRolesInUser(action.roles, currentUser);
        }
        return true;
      })
      .map(action => {
        switch (action.name) {
          case 'open':
            return (
              <OpenWithExternalApp
                key={action.name}
                patient={patient}
                buttonProps={{
                  color: 'inherit',
                  title: 'Open DIBS',
                  key: 'open'
                }}
                onClick={app => handleOpenFile(app, patientFile)}
                isDisabled={() => {
                  return patientFile.tag === 'tech_backup';
                }}
              />
            );
          case 'download':
            return (
              <IconButton
                color="inherit"
                title="Download File"
                key="download"
                onClick={() => handleDownloadFile(patientFile.id)}
              >
                <CloudDownloadIcon />
              </IconButton>
            );
          case 'delete':
            return (
              <Fragment key="delete">
                {!isPatientDeleted &&
                  (patientFile.tag !== 'tech_backup' ||
                    isSuperAdminOrAdmin(currentUser) ||
                    isLabPartner(currentUser)) && (
                    <ConfirmationDialogOnClickWrapper
                      confirmationBody={`Are you sure you want to delete file "${patientFile.original_name ||
                        patientFile.name}"?`}
                      confirmationTitle="Delete Files"
                    >
                      <IconButton
                        color="inherit"
                        title="Delete File"
                        key="delete"
                        onClick={() =>
                          handleDeleteFile(patientFile.patient_id, fileTypeId, patientFile.id)
                        }
                      >
                        <DeleteIcon color="secondary" />
                        <LoadingSpinner show={isDeletingFile} />
                      </IconButton>
                    </ConfirmationDialogOnClickWrapper>
                  )}
              </Fragment>
            );
          case 'flag-job':
            return (
              <FlagJobAction
                key={action.name}
                patientFile={patientFile}
                onFlagged={() => {
                  onFileUpdated(patientFile.id);
                }}
              />
            );
          default:
            return null;
        }
      });
  };

  if (!patientFiles.length) {
    return (
      <Paper>
        <Toolbar>
          <Typography variant="subtitle1">{title}</Typography>
          <Chip size="small" className={classes.noFoundChip} label={`No ${title} found`} />
          {!isPatientDeleted && !disableUpload && renderAddButton()}
          {renderAdditionalButtons()}
        </Toolbar>
      </Paper>
    );
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary className={classes.AccordionSummary} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle1">{title}</Typography>
        {!isPatientDeleted && !disableUpload && renderAddButton()}
        {renderAdditionalButtons()}
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Uploaded Date</TableCell>
                <TableCell align="right">Modification Date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patientFiles.map(patientFile => (
                <TableRow key={patientFile.id}>
                  <TableCell component="th" scope="row">
                    {patientFile.original_name || patientFile.name}
                    <div className={classes.labels}>
                      {patientFile.tag === 'tech_backup' && (
                        <Chip color="secondary" label="Backup" size="small" />
                      )}
                      {patientFile.tag === tags.STL_MAXILLARY && (
                        <Chip color="secondary" label="Maxillary" size="small" />
                      )}
                      {patientFile.tag === tags.STL_MANDIBULAR && (
                        <Chip color="secondary" label="Mandibular" size="small" />
                      )}
                      {_get(patientFile, 'metadata.restore_point', false) && (
                        <Chip
                          color="secondary"
                          label={patientFile.metadata.restore_point}
                          size="small"
                        />
                      )}
                      {_get(patientFile, 'metadata.relu_request.status', false) && (
                        <ReluRequestStatusChip reluRequest={patientFile.metadata.relu_request} />
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    {UTCDateTimeToLocalTime(patientFile.created_at)}
                  </TableCell>
                  <TableCell align="right">
                    {patientFile.file_modification_date
                      ? UTCDateTimeToLocalTime(patientFile.file_modification_date)
                      : UTCDateTimeToLocalTime(patientFile.updated_at)}
                  </TableCell>
                  <TableCell align="right">{renderActions(patientFile)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>

      <PatientModificationWarning
        id={patientModificationWarningId}
        onClose={onPatientModificationWarningClose}
      />
    </Accordion>
  );
};

PatientFilesTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patientFiles: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ),
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  patientModificationWarningId: PropTypes.string.isRequired,
  title: PropTypes.string,
  openUploadDialog: PropTypes.func.isRequired,
  fileTypeId: PropTypes.number.isRequired,
  isPatientDeleted: PropTypes.bool,
  preventDuplicate: PropTypes.bool,
  maxFiles: PropTypes.number,
  organizationId: PropTypes.number,
  disableUpload: PropTypes.bool,
  renderAdditionalButtons: PropTypes.func,
  onFileDeleted: PropTypes.func,
  onFileUpdated: PropTypes.func,
  validateFile: PropTypes.func
};

PatientFilesTable.defaultProps = {
  patientFiles: [],
  actions: [],
  title: '',
  isPatientDeleted: false,
  preventDuplicate: false,
  maxFiles: 1,
  organizationId: null,
  disableUpload: false,
  renderAdditionalButtons: () => <></>,
  onFileDeleted: () => {},
  onFileUpdated: () => {},
  validateFile: () => null
};

export default PatientFilesTable;
