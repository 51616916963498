import { Query } from 'cogent-js';
import axios from 'axios';
import BaseApiService from './base';

export default class InstallersApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  get(
    { perPage = 999999, page = 1, order = 'desc', orderBy, includes = [] },
    organizationId = null
  ) {
    const orderChar = order === 'desc' ? '-' : '+';
    const orderByField = orderBy || 'version_sort';
    const urlPrefix = organizationId ? `organizations/${organizationId}/` : '';
    return this.request({
      method: 'get',
      url: this.query
        .for(`${urlPrefix}installers`)
        .include(...includes)
        .sort(orderChar + orderByField)
        .params({ perPage, page })
        .get()
    });
  }

  create(data, onUploadProgress, organizationId = null) {
    const urlPrefix = organizationId ? `/organizations/${organizationId}` : '';
    return this.request({
      method: 'post',
      url: `${urlPrefix}/installers`,
      data,
      timeout: 0,
      onUploadProgress
    });
  }

  updateReleaseNotes(id, data) {
    return this.request({
      method: 'post',
      url: `/installers/${id}/update_release_notes`,
      data
    });
  }

  delete(id) {
    return this.request({
      method: 'delete',
      url: `/installers/${id}`
    });
  }

  getDownloadLink(id) {
    return this.request({
      method: 'get',
      url: `/installers/${id}/download_link`
    });
  }

  fetchAutoUpdate(organizationId = null) {
    const urlPrefix = organizationId ? `/organizations/${organizationId}` : '';
    return this.request({
      method: 'get',
      url: `${urlPrefix}/installers/get-auto-update`
    });
  }

  setAutoUpdate(value, organizationId = null) {
    const urlPrefix = organizationId ? `/organizations/${organizationId}` : '';
    return this.request({
      method: 'post',
      url: `${urlPrefix}/installers/set-auto-update`,
      data: { value }
    });
  }

  uploadMachineLearningFile(file) {
    return this.request({
      method: 'get',
      url: `/machine_learning_file/get_upload_url`
    })
      .then(response => {
        return axios.put(response.url, file, {
          headers: {
            'Content-Type': file.type
          }
        });
      })
      .then(() => {
        return this.request({
          method: 'post',
          url: `/machine_learning_file/bump_version`
        });
      });
  }

  requestDownloadUrl() {
    return this.request({
      method: 'get',
      url: `/machine_learning_file/request_download_url`
    });
  }
}
