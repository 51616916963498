import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import ErrorIcon from '@material-ui/icons/Error';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { isTechnicianOrAdmin } from '../../../helpers';

const ReluRequestStatusChip = ({ reluRequest: { status, message } }) => {
  const currentUser = useSelector(state => state.auth.user);

  if (!isTechnicianOrAdmin(currentUser)) {
    return null;
  }

  switch (status) {
    case 'failed':
      return (
        <Chip
          variant="outlined"
          color="secondary"
          label="Job - Failed"
          size="small"
          deleteIcon={
            <Tooltip title={message} arrow interactive>
              <ErrorIcon />
            </Tooltip>
          }
          onDelete={() => {}}
        />
      );
    case 'success':
      return <Chip variant="outlined" color="primary" label="Job - Completed" size="small" />;
    case 'pending':
      return <Chip variant="outlined" color="default" label="Job - Pending" size="small" />;
    case 'flagged':
      return (
        <Chip
          variant="outlined"
          color="secondary"
          label="Job - Flagged"
          size="small"
          deleteIcon={
            <Tooltip title={message} arrow interactive>
              <ReportProblemIcon />
            </Tooltip>
          }
          onDelete={() => {}}
        />
      );
    default:
      return null;
  }
};

ReluRequestStatusChip.propTypes = {
  reluRequest: PropTypes.shape({
    status: PropTypes.string.isRequired,
    message: PropTypes.string
  }).isRequired
};

export default ReluRequestStatusChip;
