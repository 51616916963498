import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { concat, get } from 'lodash';
import Link from '@material-ui/core/Link';
import createLoadingSelector from '../../selectors/loading';
import LoadingIndicator from '../../components/LoadingIndicator';
import { FETCH_PATIENT_PREFIX, fetchPatientAction } from '../../reducers/patients';
import PatientFilesTable from './components/PatientFilesTable';
import PatientFilesGallery from './components/PatientFilesGallery';
import UploadFilesDialog from '../../components/UploadFilesDialog';
import UpdatePatient from './components/UpdatePatient';
import { FILE_TYPE_IDS } from '../../constants';
import { filterFilesByType, isDoctor, isLabPartner, isTechnicianOrAdmin } from '../../helpers';
import PatientNote from '../../components/PatientNote';
import NotesWrapper from './components/NotesWrapper';
import RxFormInfo from './components/RxFormInfo';
import withOrganization from '../../hooks/withOrganization';
import ConfirmationDialogWrapper from '../../components/ConfirmationDialogWrapper';
import OnHoldNotes from '../../components/OnHoldNotes';
import Can from '../../components/Can';
import iosFileNameValidator from '../../helpers/iosFileNameValidator';
import OpenWithExternalApp from '../../components/OpenWithExternalApp';
import { openDIBS } from '../../helpers/dibs';
import RestoreBackupIos from './components/RestoreBackupIos';
import hasPermission from '../../selectors/hasPermission';
import { validateDicomUpload } from '../../components/RxForm/helpers';

const useStyles = makeStyles(theme => ({
  form: {
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2)
  }
}));

const PatientDetails = props => {
  const {
    match: { params },
    location
  } = props;

  const urlQueryParams = new URLSearchParams(location.search);
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.user);
  const IOSFileId = urlQueryParams.get('open-ios-file');
  const patientId = parseInt(params.id, 10);
  const patient = useSelector(state => {
    return state.patients.items[state.patients.items.findIndex(item => item.id === patientId)];
  });
  const rxFormId = patient && patient.rx_form && patient.rx_form.id;
  const patientFiles = concat(
    get(patient, 'patient_files') || [],
    get(patient, 'restricted_patient_files') || []
  );
  const canRestoreIosBackup = useSelector(
    state =>
      (patient &&
        hasPermission(state, {
          permissions: ['organization.patients.ios.restore-tech-backup'],
          organizationId: patient.organization_id
        })) ||
      hasPermission(state, { permissions: ['patients.ios.restore-tech-backup'] })
  );

  const iosFiles = filterFilesByType(patientFiles, FILE_TYPE_IDS.ios).filter(
    value => value.tag !== 'tech_backup' || (value.tag === 'tech_backup' && canRestoreIosBackup)
  );
  const stlFiles = filterFilesByType(patientFiles, FILE_TYPE_IDS.stl);

  const iosFile = iosFiles.length ? iosFiles[0] : null;

  const [uploadDialogParams, setUploadDialogParams] = useState({
    title: '',
    open: false,
    patientId,
    fileTypeId: FILE_TYPE_IDS.photos,
    isImage: true
  });

  const [noLoading, setNoLoading] = useState(false);

  const actions = {
    IOS: [],
    STL: [
      { name: 'download' },
      {
        name: 'delete',
        roles: ['super_admin', 'tech_support', 'supervisor', 'admin', 'lab_partner']
      }
    ],
    DICOM: [
      { name: 'flag-job' },
      { name: 'download' },
      {
        name: 'delete'
      }
    ],
    OTHER: [
      { name: 'download' },
      {
        name: 'delete'
      }
    ]
  };
  if (!iosFile && isDoctor(currentUser)) {
    actions.STL[1].roles.push('doctor');
  }

  const openUploadDialog = ({
    title,
    fileTypeId,
    isImage = true,
    maxFiles,
    preventDuplicate,
    existingFiles,
    nameValidator,
    validateFile
  }) => {
    setUploadDialogParams({
      ...uploadDialogParams,
      title,
      fileTypeId,
      open: true,
      isImage,
      maxFiles,
      preventDuplicate,
      existingFiles,
      nameValidator,
      validateFile
    });
  };

  const reloadPatientWithoutLoading = () => {
    setNoLoading(true);
    dispatch(fetchPatientAction(patientId)).finally(() => {
      setNoLoading(false);
    });
  };

  const handleUploadDialogClose = () => {
    setUploadDialogParams({ ...uploadDialogParams, open: false });

    if (!patient.has_tech_ios_backup && +uploadDialogParams.fileTypeId === FILE_TYPE_IDS.ios) {
      reloadPatientWithoutLoading();
    }
  };

  const [selectedApp, setSelectedApp] = useState(null);

  const handleOpenPatientOnDibs = app => {
    setSelectedApp(app);
    dispatch(openDIBS(app, `open_patient/${patient.id}`));
  };

  const isLoadingPatient = useSelector(state =>
    createLoadingSelector([FETCH_PATIENT_PREFIX])(state)
  );

  const filterFiles = (currentPatient, fileTypeId) => {
    if (!currentPatient || !patientFiles) {
      return [];
    }

    return filterFilesByType(patientFiles, fileTypeId);
  };

  const filterByFileExtension = (files, extension, mode = 'only') => {
    return files.filter(file => {
      switch (mode) {
        case 'exclude':
          return !file.name.toLowerCase().endsWith(extension);
        default:
          return file.name.toLowerCase().endsWith(extension);
      }
    });
  };

  useEffect(() => {
    if (selectedApp && IOSFileId) {
      dispatch(openDIBS(selectedApp, `open_file/${IOSFileId}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchPatientAction(patientId));
  }, [location, patientId, dispatch]);

  const getEasyRxPrescriptionUrl = easyRxCase => {
    const url = process.env.REACT_APP_EASY_RX_SHOW_PRESCRIPTION_URL;

    if (!url) {
      return null;
    }

    return url.replace('{prescription_id}', easyRxCase.prescription_id);
  };

  const renderAdditionFiles = () => {
    return (
      <Grid item xs={12}>
        <PatientFilesTable
          patient={patient}
          patientModificationWarningId="Patients-PatientDetails-STL"
          organizationId={patient.organization_id}
          patientFiles={filterByFileExtension(
            filterFiles(patient, FILE_TYPE_IDS.other),
            '.jpg',
            'exclude'
          )}
          fileTypeId={FILE_TYPE_IDS.other}
          actions={actions.OTHER}
          title="Other Files"
          openUploadDialog={openUploadDialog}
          isPatientDeleted={patient && Boolean(patient.deleted_at)}
          maxFiles={20}
          preventDuplicate
          addTitle='Other Files (Photos will be moved to "Other Photos" Section)'
        />
      </Grid>
    );
  };

  if (isLoadingPatient && !noLoading) {
    return <LoadingIndicator />;
  }

  if (!patient || !patientFiles || !patient.user) {
    return null;
  }

  const children = (
    <>
      <UpdatePatient patient={patient} />
      {patient.rx_form && (
        <RxFormInfo
          className={classes.form}
          id={rxFormId}
          patient={patient}
          additionalButtons={() => {
            if (
              !iosFile &&
              stlFiles.length > 0 &&
              Number(patient.rx_form.submission_completed_by) === 2
            ) {
              return (
                <OpenWithExternalApp
                  patient={patient}
                  isIconButton={false}
                  buttonProps={{
                    variant: 'contained',
                    color: 'primary',
                    title: 'Create IOS File',
                    style: { marginLeft: 'auto' }
                  }}
                  onClick={handleOpenPatientOnDibs}
                />
              );
            }
            return '';
          }}
        />
      )}
      <Card className={classes.form}>
        <CardHeader title="Additional Information" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {patient.rx_form && patient.rx_form.easy_rx_case && (
              <Grid item xs={12}>
                <NotesWrapper title="EasyRx">
                  <Link
                    href={getEasyRxPrescriptionUrl(patient.rx_form.easy_rx_case) || '#'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Prescription on EasyRx
                  </Link>
                </NotesWrapper>
              </Grid>
            )}
            <Grid item xs={12}>
              <NotesWrapper title="Doctor Notes">
                <PatientNote
                  patientId={patient.id}
                  note={patient.doctor_notes || ''}
                  noteIdentifier="doctor_notes"
                  label="Doctor Notes"
                  rows={6}
                  disabled={isTechnicianOrAdmin(currentUser) || isLabPartner(currentUser)}
                />
              </NotesWrapper>
            </Grid>
            <Grid item xs={12}>
              <NotesWrapper title="Technician Notes">
                <PatientNote
                  patientId={patient.id}
                  note={patient.management_notes || ''}
                  noteIdentifier="management_notes"
                  label="Tech Notes"
                  rows={6}
                  disabled={isDoctor(currentUser)}
                />
              </NotesWrapper>
            </Grid>
            <Can
              permissions={['patients.internal-notes.create']}
              yes={() => (
                <Grid item xs={12}>
                  <NotesWrapper title="Internal Notes">
                    <PatientNote
                      patientId={patient.id}
                      note={patient.internal_notes || ''}
                      noteIdentifier="internal_notes"
                      label="Internal Notes"
                      rows={6}
                    />
                  </NotesWrapper>
                </Grid>
              )}
            />
            <Grid item xs={12}>
              <NotesWrapper title="On Hold Notes">
                <OnHoldNotes
                  patientId={patient.id}
                  notes={patient.on_hold_notes || []}
                  label="On Hold Notes"
                  rows={6}
                  disabled={!patient.is_on_hold}
                />
              </NotesWrapper>
            </Grid>
            <Grid item xs={12}>
              <ConfirmationDialogWrapper declineText="No" confirmText="Yes">
                {({ confirm }) => {
                  const handler = props => {
                    if (iosFiles.filter(f => !+f.hidden).length !== 0) {
                      confirm(
                        'Warning: IOS File already exists!',
                        'Patient with multiple IOS files can cause synchronization problem on DIBS. Do you want to proceed?',
                        () => {
                          openUploadDialog({
                            ...props,
                            nameValidator: name =>
                              iosFileNameValidator(name, props.existingFiles, patient)
                          });
                        }
                      );
                    } else {
                      openUploadDialog({
                        ...props,
                        nameValidator: name =>
                          iosFileNameValidator(name, props.existingFiles, patient)
                      });
                    }
                  };

                  return (
                    <PatientFilesTable
                      patient={patient}
                      patientModificationWarningId="Patients-PatientDetails-IOS"
                      organizationId={patient.organization_id}
                      patientFiles={iosFiles}
                      fileTypeId={FILE_TYPE_IDS.ios}
                      actions={[
                        { name: 'open' },
                        { name: 'download' },
                        {
                          name: 'delete',
                          roles: [
                            'super_admin',
                            'admin',
                            'lab_partner',
                            'tech_support',
                            'supervisor'
                          ]
                        }
                      ]}
                      title="IOS Files"
                      openUploadDialog={handler}
                      isPatientDeleted={patient && Boolean(patient.deleted_at)}
                      preventDuplicate
                      disableUpload={isDoctor(currentUser)}
                      renderAdditionalButtons={() => (
                        <RestoreBackupIos
                          patient={patient}
                          hidden={
                            !(
                              canRestoreIosBackup &&
                              patient.has_tech_ios_backup &&
                              iosFiles.length === 1
                            )
                          }
                        />
                      )}
                      onFileDeleted={id => {
                        if (iosFiles.filter(f => f.hidden && f.id === id).length >= 0) {
                          reloadPatientWithoutLoading();
                        }
                      }}
                    />
                  );
                }}
              </ConfirmationDialogWrapper>
            </Grid>

            <Grid item xs={12}>
              <PatientFilesTable
                patient={patient}
                patientModificationWarningId="Patients-PatientDetails-STL"
                organizationId={patient.organization_id}
                patientFiles={filterFiles(patient, FILE_TYPE_IDS.stl)}
                fileTypeId={FILE_TYPE_IDS.stl}
                actions={actions.STL}
                title="STL Files"
                openUploadDialog={openUploadDialog}
                isPatientDeleted={patient && Boolean(patient.deleted_at)}
                maxFiles={20}
                disableUpload={isDoctor(currentUser) && !!iosFile}
                preventDuplicate
              />
            </Grid>

            <Grid item xs={12}>
              <PatientFilesGallery
                title="Photos"
                patient={patient}
                patientFiles={filterFiles(patient, FILE_TYPE_IDS.photos)}
                fileTypeId={FILE_TYPE_IDS.photos}
                openUploadDialog={openUploadDialog}
                showNumberOfImages
                expandedAndLoadImages
                fullHeightImage
              />
            </Grid>

            <Grid item xs={12}>
              <PatientFilesTable
                patient={patient}
                patientModificationWarningId="Patients-PatientDetails-DICOM"
                organizationId={patient.organization_id}
                patientFiles={filterFiles(patient, FILE_TYPE_IDS.dicom)}
                fileTypeId={FILE_TYPE_IDS.dicom}
                actions={actions.DICOM}
                title="Dicom Files"
                openUploadDialog={openUploadDialog}
                isPatientDeleted={patient && Boolean(patient.deleted_at)}
                maxFiles={1}
                disableUpload={
                  Array.isArray(filterFiles(patient, FILE_TYPE_IDS.dicom)) &&
                  filterFiles(patient, FILE_TYPE_IDS.dicom).length >= 1
                }
                onFileUpdated={() => {
                  reloadPatientWithoutLoading();
                }}
                validateFile={validateDicomUpload}
              />
            </Grid>

            <Grid item xs={12}>
              <PatientFilesGallery
                title="X-Ray Images"
                patient={patient}
                patientFiles={filterFiles(patient, FILE_TYPE_IDS.xray)}
                fileTypeId={FILE_TYPE_IDS.xray}
                openUploadDialog={openUploadDialog}
                showNumberOfImages
              />
            </Grid>

            <Grid item xs={12}>
              <PatientFilesGallery
                title="IDB Tray Images"
                patient={patient}
                patientFiles={filterFiles(patient, FILE_TYPE_IDS.brackets)}
                fileTypeId={FILE_TYPE_IDS.brackets}
                openUploadDialog={openUploadDialog}
                showNumberOfImages
              />
            </Grid>

            <Grid item xs={12}>
              <PatientFilesGallery
                title="Other Photos"
                patient={patient}
                patientFiles={filterByFileExtension(
                  filterFiles(patient, FILE_TYPE_IDS.other),
                  '.jpg'
                )}
                fileTypeId={FILE_TYPE_IDS.other}
                showNumberOfImages
              />
            </Grid>
            {renderAdditionFiles()}
          </Grid>
        </CardContent>
      </Card>
      <UploadFilesDialog
        title={uploadDialogParams.title}
        patientFileTypeId={uploadDialogParams.fileTypeId}
        patientId={uploadDialogParams.patientId}
        handleClose={handleUploadDialogClose}
        open={uploadDialogParams.open}
        isImageUploader={uploadDialogParams.isImage}
        updatePatient
        maxFiles={uploadDialogParams.maxFiles}
        preventDuplicate={uploadDialogParams.preventDuplicate}
        existingFiles={uploadDialogParams.existingFiles}
        nameValidator={uploadDialogParams.nameValidator}
        validateFile={uploadDialogParams.validateFile}
      />
    </>
  );

  if (isDoctor(currentUser) || isLabPartner(currentUser)) {
    // load user's organization permissions
    return withOrganization({ organizationId: patient.organization_id, children: () => children });
  }
  return children;
};

PatientDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired
};

PatientDetails.defaultProps = {
  match: { params: {} }
};

export default PatientDetails;
